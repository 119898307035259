@font-face {
  font-family: "THSarabunNewRegular";
  src: url("http://webfont.webdesigner.in.th/font/THSarabunNew/thsarabunnew.eot");
  src: url("http://webfont.webdesigner.in.th/font/THSarabunNew/thsarabunnew.eot")
      format("embedded-opentype"),
    url("http://webfont.webdesigner.in.th/font/THSarabunNew/thsarabunnew.woff")
      format("woff"),
    url("http://webfont.webdesigner.in.th/font/THSarabunNew/thsarabunnew.ttf")
      format("truetype"),
    url("http://webfont.webdesigner.in.th/font/THSarabunNew/thsarabunnew.svg#THSarabunNewRegular")
      format("svg");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "THSarabunNewRegular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
